import './App.css';
import React from 'react';
import BarcodePlugin from './BarcodePlugin.jsx'
import ProductionStepsData from './ProductionStepsData';
import {TextField } from '@mui/material';
import { Html5QrcodeSupportedFormats, Html5QrcodeScanType } from "html5-qrcode";
import Fab from '@mui/material/Fab';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import ErrorHandler from './ErrorHandler';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      decodedResults: [],
      showTable: false,
      pt: ""
    }

    this.onNewScanResult = this.onNewScanResult.bind(this);
  }
  
  render() {
    return (
      <>
        <ErrorHandler>
          <MsalAuthenticationTemplate interactionType={InteractionType.Popup}>
            <div className="App">
              
              <section className="App-section">
                {!this.state.showTable && 
                <div>
                  <img src="logo.jpg" style={{width:"150px"}} alt="Logo Ginori 1735"/><br/><br/>
                  <TextField id="ptText" label="Production Order#" style={{width: 300}} onKeyDown={this.search}/><br/><br/>
                  <BarcodePlugin fps={10} 
                                qrbox={{ width: 300, height: 100 }} 
                                aspectRatio={0.7} 
                                disableFlip={false} 
                                qrCodeSuccessCallback={this.onNewScanResult} rememberLastUsedCamera={true} 
                                supportedScanTypes={[Html5QrcodeScanType.SCAN_TYPE_CAMERA]}
                                formatsToSupport = {[Html5QrcodeSupportedFormats.CODE_128]}/><br/>
                </div>}
                
                {this.state.showTable && <ProductionStepsData pt={this.state.pt}/>}
                <br/>
                {this.state.showTable && <Fab variant="extended" size="medium" color="primary" onClick={this.reset}>
                                          <ArrowBackIosOutlinedIcon sx={{ mr: 1 }} />
                                          Back
                                          </Fab>}
              </section>
            </div>
          </MsalAuthenticationTemplate>
        </ErrorHandler>
      </>
    );
  }

  reset = () => {
    this.setState((state, props) => {
      state.showTable = false;
      return state;
    });
  }

  search = (e) => {
    
    if(e.keyCode === 13){
      this.setState((state, props) => {
        state.pt = e.target.value;
        state.showTable = true;
        return state;
      });      
    }
  }

  onNewScanResult(decodedText, decodedResult) {
    this.setState((state, props) => {
      state.decodedResults.push(decodedResult);
      state.pt = decodedResult.decodedText;
      state.showTable = true;
      return state;
    });
  }
}

export default App