import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "./msalConfig.js"

export default function ProductionStepsData(props) {
  const [steps, setSteps] = useState(null);
  const [loadingData, SetLoadingData] = useState(true);
  const [notFound, SetNotFound] = useState(false);
  const { instance, accounts, inProgress } = useMsal();
  
  useEffect(() => {
    
    const fetchPostList = async (accessToken) => {
      // const url = "https://wa-pdfgen-prod-fr-001.azurewebsites.net/api/values/techsheet/" + props.pt;
      const url = window.env.REACT_APP_API_BASE_URL + "/api/values/techsheet/" + props.pt;
      var data;
      var headers = new Headers();
      var bearer = "Bearer " + accessToken;
      headers.append("Authorization", bearer);
      var options = {
          method: "GET",
          mode: 'cors',
          headers: headers
      };
      const response = await fetch(url, options);
      
      if (response.status === 404) {
        SetNotFound(true);
        
      }
      else {
        data = await response.json();
        setSteps(data);
      }
      
      SetLoadingData(false);
      
    };

    let azureScope = "api://" + msalConfig.auth.clientId + "/access_as_user";
    let accessTokenRequest = {
      scopes: [azureScope],
      account: accounts[0],
    };

    instance
    .acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      // Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;
      // Call API with token
      fetchPostList(accessToken);
    })
    .catch(function (error) {
      //Acquire token silent failure
      console.log(error);
    });
  }, [props.pt])

    
  return (
    <div>
    {!loadingData && !notFound && 
      <Paper  elevation={0}>
        <b>Model/Size:</b> {steps[0].modelCode} <b>Color:</b> {steps[0].colorCode}<br/>
        <b>Class:</b> {steps[0].itemClass}<br/>
        <b>Description:</b> {steps[0].modelDescription}
        </Paper>}
    {!loadingData && <br/>}
    
    {loadingData ? (<p>Loading Please Wait...</p>) : notFound ? (<p className='error'>NOT FOUND!!!!!</p>) : (
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: 250 }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Kiln</TableCell>
            <TableCell align="left">Step</TableCell>
            <TableCell align="right">Temperature</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {steps.map((step) => (
            <TableRow key={step.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{step.kilnDescription}</TableCell>
              <TableCell align="left">{step.step}</TableCell>
              <TableCell align="right">{step.temperature}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    )}
    </div>
  );
}

  

