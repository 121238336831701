import { Html5QrcodeScanner } from "html5-qrcode";
import React from 'react';

const qrcodeRegionId = "html5qr-code-full-region";


class BarcodePlugin extends React.Component {
    render() {
        return <div id={qrcodeRegionId} style={{width: "100%", height:"150px"}}/>;
    }

    componentWillUnmount() {
        this.html5QrcodeScanner.clear().catch(error => {
            console.error("Failed to clear html5QrcodeScanner. ", error);
        });
    }

    componentDidMount() {
        // Creates the configuration object for Html5QrcodeScanner.
        function createConfig(props) {
            var config = {};
            
            
            if (props.fps) config.fps = props.fps;
            if (props.qrbox) config.qrbox = props.qrbox;
            if (props.aspectRatio) config.aspectRatio = props.aspectRatio;
            if (props.disableFlip !== undefined) config.disableFlip = props.disableFlip;
            if (props.rememberLastUsedCamera !== undefined) config.rememberLastUsedCamera = props.rememberLastUsedCamera;
            if (props.supportedScanTypes !== undefined) config.supportedScanTypes = props.supportedScanTypes;
            if (props.formatsToSupport !== undefined) config.formatsToSupport = props.formatsToSupport;
            return config;
        }

        var config = createConfig(this.props);
        var verbose = this.props.verbose === true;

        
        this.html5QrcodeScanner = new Html5QrcodeScanner(
            qrcodeRegionId, config, verbose);
        
        this.html5QrcodeScanner.render(
            this.props.qrCodeSuccessCallback,
            this.props.qrCodeErrorCallback);
        
    }
};

export default BarcodePlugin;
